import { UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'

import {
    BeneficialOwner,
    DocumentType,
    HasBeneficiary,
    ILegalEntityProfile,
    LegalEntityFormValues,
    PublicStatusType,
    UILicActivityType,
    UserAccountProfileDetails,
} from '../../models'
import { getDateWithoutTime, getDateWithoutTimeString } from '../../utils'

import { getInitialFile, getInitialFiles, mapFilesList } from '../common'

import { beneficiariesFrontToBack, beneficiaryBackToFront } from './beneficiary'
import {
    getBankAccountDetailsFromBackend,
    getBankAccountDetailsFromForm,
    getBankDetailsProfileFromForm,
    getBasicInformationFromBackend,
    getBasicInformationFromForm,
    getCommonDataFromBackend,
    getCommonDataFromForm,
    getInitialList,
    getLicenseFromForm,
    getLicensesFromBackend,
} from './common'

const getValueFromList = <T extends string>(fieldName: T, list: Record<T, string>[]) =>
    list?.length
        ? list.filter((item) => Boolean(item[fieldName])).map((item) => item[fieldName])
        : []

export const legalEntityFrontToBack = (
    valuesFromForm: LegalEntityFormValues,
): ILegalEntityProfile => {
    const migrationCardSoleExecutive = valuesFromForm['sole_executive.HasMigrationCard']
        ? {
              number: valuesFromForm['sole_executive.MigrationCardNumber'] as string,
              start_date: valuesFromForm[
                  'sole_executive.MigrationCardArrivalDate'
              ]?.unix() as number,
              expiration_date: valuesFromForm[
                  'sole_executive.MigrationCardDepartureDate'
              ]?.unix() as number,
              file_uuid: valuesFromForm['sole_executive.MigrationCardDocuments']?.[0]
                  ?.uid as string,
          }
        : undefined
    const permissionStayRfExecutive = valuesFromForm['sole_executive.HasMigrationPermit']
        ? {
              name: valuesFromForm['sole_executive.MigrationPermitName'] as string,
              series: valuesFromForm['sole_executive.MigrationPermitSeries'] as string,
              number: valuesFromForm['sole_executive.MigrationPermitNumber'] as string,
              start_date: valuesFromForm[
                  'sole_executive.MigrationPermitArrivalDate'
              ]?.unix() as number,
              expiration_date: valuesFromForm[
                  'sole_executive.MigrationPermitDepartureDate'
              ]?.unix() as number,
              file_uuid: valuesFromForm['sole_executive.MigrationPermitDocuments']?.[0]
                  ?.uid as string,
          }
        : undefined

    const passportData =
        valuesFromForm['sole_executive.person_document.documentType'] === DocumentType.passport
            ? {
                  identity_document: valuesFromForm['sole_executive.person_document.documentType'],
                  passport: {
                      series: valuesFromForm['sole_executive.person_document.PassportSeries'],
                      number: valuesFromForm['sole_executive.person_document.PassportNumber'],
                      issue_date:
                          valuesFromForm['sole_executive.person_document.issueDate']?.unix(),
                      issued_by: valuesFromForm['sole_executive.person_document.PassportIssuer'],
                      issuer_department_code:
                          valuesFromForm['sole_executive.person_document.PassportCode'],
                      file_uuid: valuesFromForm['sole_executive.person_document.PassportPages']
                          ?.map(({ uid }) => uid)
                          .join(),
                  },
              }
            : { identity_document: valuesFromForm['sole_executive.person_document.documentType'] }

    return {
        id: valuesFromForm.id,
        title: valuesFromForm.title,
        short_title: valuesFromForm.short_title,
        legal_form: valuesFromForm.legal_form,
        inn: valuesFromForm.inn,
        inn_file_uuid: valuesFromForm.inn_file_uuid?.map(({ uid }) => uid).join(),
        okpo: valuesFromForm.okpo,
        ogrn: valuesFromForm.ogrn,
        ogrn_file_uuid: valuesFromForm.ogrn_file_uuid?.map(({ uid }) => uid).join(),
        ogrn_date: valuesFromForm.ogrn_date?.unix(),
        okved: valuesFromForm.okveds?.map(({ okved }) => okved).filter(Boolean),
        kpp: valuesFromForm.kpp,
        legal_address: valuesFromForm['legal_address.registration'],
        location_address:
            valuesFromForm['legal_address.fact'] || valuesFromForm['legal_address.registration'],
        post_address: valuesFromForm['legal_address.index'],
        emails: getValueFromList('email', valuesFromForm.emails),
        websites: getValueFromList('website', valuesFromForm.websites),
        registration_authority: valuesFromForm.registration_authority,
        phone_numbers: getValueFromList('phone_number', valuesFromForm.phone_numbers),
        ...getCommonDataFromForm(valuesFromForm),
        business_reputations: valuesFromForm.business_reputations?.map((item) => ({
            ...item,
            file_uuids: item.file_uuids?.map(({ uid }) => uid),
        })),
        beneficial_owners_uuids: [],
        lic_activities: getLicenseFromForm(valuesFromForm.lic_activities),
        sole_executive: {
            position: valuesFromForm['sole_executive.position'],
            position_file_uuid: valuesFromForm['sole_executive.position_file_id']
                ?.map(({ uid }) => uid)
                .join(),
            location_address:
                valuesFromForm['sole_executive.address.fact'] ||
                valuesFromForm['sole_executive.address.registration'],
            post_address: valuesFromForm['sole_executive.address.index'],
            inn: valuesFromForm['sole_executive.inn'],
            phone_number: valuesFromForm['sole_executive.phone_number'],
            email: valuesFromForm['sole_executive.email'] ?? '',
            person: {
                first_name: valuesFromForm['sole_executive.first_name'],
                last_name: valuesFromForm['sole_executive.last_name'],
                second_name: valuesFromForm['sole_executive.second_name'],
                birthdate: getDateWithoutTime(
                    valuesFromForm['sole_executive.birthdate'],
                ) as unknown as number,
                place_birth: valuesFromForm['sole_executive.place_birth'],
                citizenship: valuesFromForm['sole_executive.citizenship'],
                registration_address: valuesFromForm['sole_executive.address.registration'],
                ...passportData,
                identity_document_file_uuid: valuesFromForm[
                    'sole_executive.person_document.PassportPages'
                ]
                    ?.map(({ uid }) => uid)
                    .join(),
            },
            migration_card: migrationCardSoleExecutive,
            permission_stay_rf: permissionStayRfExecutive,
        },
        bank_details: getBankDetailsProfileFromForm(valuesFromForm),
        bank_account_details: getBankAccountDetailsFromForm(
            valuesFromForm,
            `${valuesFromForm.title}`,
        ),
        basic_information: getBasicInformationFromForm(valuesFromForm),
        state_registration_file_uuids: valuesFromForm.state_registration_file_uuids.map(
            ({ uid }) => uid,
        ),
        constituent_documents_file_uuids: valuesFromForm.constituent_documents_file_uuids.map(
            ({ uid }) => uid,
        ),
        rent_premise_file_uuids: valuesFromForm.rent_premise_file_uuids.map(({ uid }) => uid),
        organ: {
            organizational_structure: valuesFromForm.organizational_structure,
            members: valuesFromForm.members,
        },
        bik: valuesFromForm.bik,
        okato: valuesFromForm.okato,

        public_official: {
            am_i: valuesFromForm['public_official.status'] === PublicStatusType.public,
            is_relative: valuesFromForm['public_official.status'] === PublicStatusType.related,
            relation: valuesFromForm['public_official.relation'],
            job: {
                country: valuesFromForm['public_official.job.country'],
                title: valuesFromForm['public_official.job.title'],
                official_address: valuesFromForm['public_official.job.official_address'],
                position_title: valuesFromForm['public_official.job.position_title'],
            },
        },

        owns_quarter: valuesFromForm.owns_quarter,
        legal_entity_control: valuesFromForm.legal_entity_control,
    }
}

export const legalEntityBackToFront = (
    valuesFromBackend: ILegalEntityProfile,
    beneficialOwners?: BeneficialOwner[],
): LegalEntityFormValues => {
    const migrationDataSoleExecutive = valuesFromBackend.sole_executive?.migration_card?.number
        ? {
              'sole_executive.HasMigrationCard': true,
              'sole_executive.MigrationCardArrivalDate': moment.unix(
                  valuesFromBackend.sole_executive.migration_card.start_date,
              ),
              'sole_executive.MigrationCardDepartureDate': moment.unix(
                  valuesFromBackend.sole_executive.migration_card.expiration_date,
              ),
              'sole_executive.MigrationCardDocuments': getInitialFile(
                  valuesFromBackend.sole_executive.migration_card.file_uuid,
              ),
              'sole_executive.MigrationCardNumber':
                  valuesFromBackend.sole_executive.migration_card.number,
          }
        : {
              'sole_executive.HasMigrationCard': false,
          }
    const permissionDataSoleExecutive = valuesFromBackend.sole_executive?.permission_stay_rf?.number
        ? {
              'sole_executive.HasMigrationPermit': true,
              'sole_executive.MigrationPermitArrivalDate': moment.unix(
                  valuesFromBackend.sole_executive.permission_stay_rf.start_date,
              ),
              'sole_executive.MigrationPermitDepartureDate': moment.unix(
                  valuesFromBackend.sole_executive.permission_stay_rf.expiration_date,
              ),
              'sole_executive.MigrationPermitDocuments': getInitialFile(
                  valuesFromBackend.sole_executive.permission_stay_rf.file_uuid,
              ),
              'sole_executive.MigrationPermitName':
                  valuesFromBackend.sole_executive.permission_stay_rf.name,
              'sole_executive.MigrationPermitNumber':
                  valuesFromBackend.sole_executive.permission_stay_rf.number,
              'sole_executive.MigrationPermitSeries':
                  valuesFromBackend.sole_executive.permission_stay_rf.series,
          }
        : {
              'sole_executive.HasMigrationPermit': false,
          }

    const passportDateSoleExecutive =
        valuesFromBackend.sole_executive?.person.identity_document === DocumentType.passport
            ? {
                  'sole_executive.person_document.documentType':
                      valuesFromBackend.sole_executive?.person.identity_document,
                  'sole_executive.person_document.PassportCode':
                      valuesFromBackend.sole_executive?.person.passport.issuer_department_code,
                  'sole_executive.person_document.PassportIssuer':
                      valuesFromBackend.sole_executive?.person.passport.issued_by,
                  'sole_executive.person_document.PassportNumber':
                      valuesFromBackend.sole_executive?.person.passport.number,
                  'sole_executive.person_document.PassportSeries':
                      valuesFromBackend.sole_executive?.person.passport.series,
                  'sole_executive.person_document.issueDate': moment.unix(
                      valuesFromBackend.sole_executive?.person.passport.issue_date,
                  ),
                  'sole_executive.person_document.PassportPages': getInitialFile(
                      valuesFromBackend.sole_executive?.person?.identity_document_file_uuid,
                  ),
              }
            : {
                  'sole_executive.person_document.documentType':
                      valuesFromBackend.sole_executive?.person.identity_document,
              }
    return {
        id: valuesFromBackend.id,
        title: valuesFromBackend.title,
        short_title: valuesFromBackend.short_title,
        legal_form: valuesFromBackend.legal_form,
        inn: valuesFromBackend.inn,
        inn_file_uuid: getInitialFile(valuesFromBackend.inn_file_uuid) as UploadFile[],
        okpo: valuesFromBackend.okpo,
        ogrn: valuesFromBackend.ogrn,
        ogrn_file_uuid: getInitialFile(valuesFromBackend.ogrn_file_uuid) as UploadFile[],
        ogrn_date: moment.unix(valuesFromBackend.ogrn_date),
        kpp: valuesFromBackend.kpp,
        okveds: getInitialList('okved', valuesFromBackend.okved),
        'legal_address.registration': valuesFromBackend.legal_address,
        'legal_address.fact': valuesFromBackend.location_address,
        'legal_address.index': valuesFromBackend.post_address,
        registration_authority: valuesFromBackend.registration_authority,
        emails: getInitialList('email', valuesFromBackend.emails),
        websites: getInitialList('website', valuesFromBackend.websites),
        phone_numbers: getInitialList('phone_number', valuesFromBackend.phone_numbers),

        business_reputations: valuesFromBackend.business_reputations?.map((item) => ({
            ...item,
            file_uuids: getInitialFiles(item.file_uuids) as UploadFile[],
        })),
        lic_activities: getLicensesFromBackend(
            valuesFromBackend.lic_activities,
        ) as UILicActivityType[],
        ...getBasicInformationFromBackend(valuesFromBackend.basic_information),
        ...getBankAccountDetailsFromBackend(
            valuesFromBackend.bank_account_details ??
                valuesFromBackend?.user_bank_account_details?.bank_account_details,
        ),
        'sole_executive.position': valuesFromBackend.sole_executive?.position,
        'sole_executive.position_file_id': getInitialFile(
            valuesFromBackend?.sole_executive.position_file_uuid,
        ),
        'sole_executive.address.registration':
            valuesFromBackend.sole_executive?.person?.registration_address,
        'sole_executive.address.fact': valuesFromBackend.sole_executive?.location_address,
        'sole_executive.address.index': valuesFromBackend.sole_executive?.post_address,
        'sole_executive.inn': valuesFromBackend.sole_executive?.inn,
        'sole_executive.phone_number': valuesFromBackend.sole_executive?.phone_number,
        'sole_executive.email': valuesFromBackend.sole_executive?.email,
        'sole_executive.first_name': valuesFromBackend.sole_executive?.person?.first_name,
        'sole_executive.last_name': valuesFromBackend.sole_executive?.person?.last_name,
        'sole_executive.second_name': valuesFromBackend.sole_executive?.person?.second_name,
        'sole_executive.birthdate': getDateWithoutTimeString(
            valuesFromBackend.sole_executive?.person?.birthdate,
        ),
        'sole_executive.place_birth': valuesFromBackend.sole_executive?.person?.place_birth,
        'sole_executive.citizenship': valuesFromBackend.sole_executive?.person?.citizenship,
        ...passportDateSoleExecutive,
        ...migrationDataSoleExecutive,
        ...permissionDataSoleExecutive,
        beneficial_owners_uuids: beneficialOwners?.map(beneficiaryBackToFront),
        hasBeneficiary: valuesFromBackend.beneficial_owners_uuids?.length
            ? HasBeneficiary.YES
            : HasBeneficiary.NO,
        state_registration_file_uuids: getInitialFiles(
            valuesFromBackend.state_registration_file_uuids,
        ) as UploadFile[],
        constituent_documents_file_uuids: getInitialFiles(
            valuesFromBackend.constituent_documents_file_uuids,
        ) as UploadFile[],
        rent_premise_file_uuids: getInitialFiles(
            valuesFromBackend.rent_premise_file_uuids,
        ) as UploadFile[],
        organizational_structure: valuesFromBackend.organ?.organizational_structure,
        members: valuesFromBackend.organ?.members,
        ...getCommonDataFromBackend(valuesFromBackend),
        bik: valuesFromBackend.bik,
        okato: valuesFromBackend.okato,

        'public_official.relation': valuesFromBackend.public_official.relation,
        'public_official.am_i': valuesFromBackend.public_official.am_i,
        'public_official.is_relative': valuesFromBackend.public_official.is_relative,
        'public_official.job.official_address':
            valuesFromBackend.public_official.job.official_address,
        'public_official.job.country': 'ru', //valuesFromBackend.public_official.job.country,
        'public_official.job.title': valuesFromBackend.public_official.job.title,
        'public_official.job.position_title': valuesFromBackend.public_official.job.position_title,
        'public_official.status': valuesFromBackend.public_official.am_i
            ? PublicStatusType.public
            : valuesFromBackend.public_official.is_relative
            ? PublicStatusType.related
            : PublicStatusType.notPublic,

        owns_quarter: valuesFromBackend.owns_quarter,
        legal_entity_control: valuesFromBackend.legal_entity_control,
    }
}

export const legalEntityForUpdateData = (
    valuesFromForm: LegalEntityFormValues,
    oldProfileFromBackend: ILegalEntityProfile,
) => {
    console.log('*** update', valuesFromForm)
    const newProfile = legalEntityFrontToBack(valuesFromForm)
    const account_details = {
        ...oldProfileFromBackend.user_bank_account_details?.bank_account_details,
        ...newProfile.bank_account_details,
    }

    return {
        legal_entity: {
            ...oldProfileFromBackend,
            ...newProfile,
            node_and_exchange_operator: undefined,
        },
        linked_beneficiaries:
            valuesFromForm.beneficial_owners_uuids &&
            valuesFromForm.hasBeneficiary === HasBeneficiary.YES
                ? beneficiariesFrontToBack(valuesFromForm.beneficial_owners_uuids)
                : undefined,
        user_account_details: [
            {
                ...oldProfileFromBackend.user_bank_account_details,
                bank_account_details: undefined,
                account_details,
            } as UserAccountProfileDetails,
        ],
    }
}
